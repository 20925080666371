<template>
  <div class="login">
    <div class="contain">
      <h2>获取报告</h2>
      <div class="content">
        <div class="textBox">
          <div class="name">电话号码：</div>
          <div class="inputBox">
            <input
              type="text"
              v-model="form.phone"
              placeholder="请输入电话号码"
            />
          </div>
          <div class="name" style="margin-top: 10px">姓名：</div>
          <div class="inputBox">
            <input type="text" v-model="form.name" placeholder="请输入姓名" />
          </div>
          <div class="btnBox" @click="login()">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        phone: "",
        name: "",
      },
    };
  },
  methods: {
    async login() {
      if (!this.form.phone || !this.form.name) {
        this.$message.error("请输入电话号码和姓名");
        return;
      }
      const res = await this.getAjax(
        `api/wx/user/base/searchByPhoneAndName`,
        this.form,
        "post"
      );
      if (!res.data) {
        this.$message.error("未找到报告,请确认信息是否正确");
        return;
      }
      this.$router.push({
        name: "report",
        query: this.form,
      });
    },
  },
  created() {
    //获取正常身高体重表
    this.getAjax(
      "api/wx/user/base/getHw",
      {
        age: "",
        sex: true,
      },
      "post"
    ).then((gres) => {
      this.setItem("gHeight", gres.data.heights);
      this.setItem("gWeight", gres.data.weights);
      this.getAjax(
        "api/wx/user/base/getHw",
        {
          age: "",
          sex: false,
        },
        "post"
      ).then((gres) => {
        this.setItem("bHeight", gres.data.heights);
        this.setItem("bWeight", gres.data.weights);
      });
    });
  },
  mounted() {},
};
</script>

<style scoped lang="less">
.login {
  width: 100%;
  box-sizing: border-box;
  padding: 0 15px;
  position: fixed;
  top: 50%;
  margin-top: -166px;
  .textBox {
    .inputBox {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
      input {
        width: 100%;
      }
    }
    .btnBox {
      height: 50px;
      line-height: 50px;
      text-align: center;
      margin: 30px auto 10px;
      background: #1fb6c1;
      color: #fff;
      border-radius: 8px;
      font-size: 16px;
    }
  }
  .contain {
    margin-top: 15px;
    border: 1px solid #1fb6c1;
    border-radius: 10px;
    overflow: hidden;
  }
  .contain h2 {
    height: 40px;
    background: #1fb6c1;
    color: #fff;
    padding: 0 10px;
    display: flex;
    align-items: center;
    font-size: 16px;
    justify-content: center;
  }
  .contain .content {
    padding: 15px;
  }
}
</style>
